import React from "react";
import LogoVideo from "../../images/dealershiplogofade.webm"
import LogoVideoMp4 from "../../images/dealershiplogofade.mp4"
import LazyLoad from 'react-lazyload';
//import {GatsbyImage} from 'exo-plugin-image'

const Membership = ({homeData}) => {

  return (
    <div className="w-full">
      <div className="max-w-7xl mx-auto px-7">
        <div className="flex flex-col-reverse md:flex-row justify-between">
          <div className="videoWrapper rounded-lg pb-10 md:pt-24 md:pl-4 md:pr-20">
          <LazyLoad height={100}>
            <video autoPlay="autoplay" className="rounded-lg new-shadow" playsInline="playsinline" muted="muted" >
              <source src={LogoVideo} type="video/webm" />
              <source src={LogoVideoMp4} type="video/mp4" />
            </video>
          </LazyLoad>
          </div>
          <div className="py-14 md:pt-36 md:px-10">
            <h1 className="text-black text-3xl md:text-5xl font-bold pb-3 max-w-lg headerTitle">
              {homeData !== null && homeData !== undefined
                ? homeData.mention
                : null}
            </h1>
            <p className="text-base md:text-xl text-black font-normal md:max-w-xl">
              {homeData !== null && homeData !== undefined
                ? homeData.description
                : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
