import React from "react";
import {GatsbyImage} from 'exo-plugin-image'
import LazyLoad from 'react-lazyload';

const Join = ({homeData}) => {
  const sanityConfig = {projectId: 'x3j0va1l', dataset: 'production'}
  const image = homeData && homeData.mainImage && JSON.parse(JSON.stringify(homeData.mainImage.asset.exoImageData));

  return (
    <div className="w-full">
      <div className="max-w-7xl mx-auto px-7">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="py-10 md:py-36 md:px-10">
            <h1 className="pr-16 md:pr-0 text-black text-3xl md:text-5xl font-bold pb-3 max-w-lg headerTitle">
              {homeData !== null && homeData !== undefined
                ? homeData.mention
                : null}
            </h1>
            <p className=" text-base md:text-xl text-black font-normal md:max-w-xl">
              {homeData !== null && homeData !== undefined
                ? homeData.description
                : null}
            </p>
          </div>
          <div className="videoWrapper rounded-lg py-0 pb-10 md:py-20 md:pb-24 md:pl-10 md:pr-24">
          {homeData && (
              <GatsbyImage className="rounded-lg new-shadow" alt={homeData.mainImage.alt} image={image} />
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Join;
