import React from "react";
import {GatsbyImage} from 'exo-plugin-image'
import LazyLoad from 'react-lazyload';

const Header = ({homeData}) => {

  const sanityConfig = {projectId: 'x3j0va1l', dataset: 'production'}
  const image = homeData && homeData.mainImage && JSON.parse(JSON.stringify(homeData.mainImage.asset.exoImageData));
    
  return (
    <div className="w-full pt-14 md:pt-0 bg-yellow">
      <div className="max-w-7xl mx-auto md:pt-32 px-7">
        <div className="flex flex-col justify-center md:flex-row md:justify-between">
          <div className="pt-16 pb-12 md:py-36 md:px-5">
            <h1 className="text-black text-3xl md:text-5xl font-bold pb-6 md:pb-3 md:max-w-lg headerTitle">
              {homeData !== null && homeData !== undefined
                ? homeData.mention
                : null}
            </h1>
            <p className=" text-lg md:text-xl text-black font-normal md:max-w-lg">
              {homeData !== null && homeData !== undefined
                ? homeData.description
                : null}
            </p>
          </div>
          <div className="w-full md:max-w-md md:pr-20 pb-16 md:py-10 rounded-lg">
          {homeData && (
              <GatsbyImage className="rounded-lg new-shadow md:w-full" alt={homeData.mainImage.alt} image={image} />
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
